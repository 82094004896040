export default {
  namespaced: true,
  state: {
    isLoading: false,
    loaderType: "default",
  },
  mutations: {
    setLoading(state, status) {
      state.isLoading = status;
    },
    setLoaderType(state, value) {
      state.loaderType = value;
    },
  },
  actions: {
    setLoadingStatus({ commit }, status) {
      commit("setLoading", status);
    },
  },
  getters: {
    getLoadingStatus(state) {
      return state.isLoading;
    },
    getLoaderType(state) {
      return state.loaderType;
    },
  },
};
