<template>
  <section>
    <div class="account-detail">
      <div>
        <AdminDataTable
          @onAction="actions.showAddAccountDialog = true"
          :headers="headers"
          :data="sanitizedItems"
          :accountsData="accountItems"
          @rowItemClick="showRowActions"
          @appliedFilter="appliedFilter($event)"
          :pagination="paginationObj"
        />
      </div>
    </div>
    <AdminCreateAccountDialog
      v-model="actions.showAddAccountDialog"
      @input="onCloseModal"
      @onSave="saveAccount"
      :current-account="currentAccount"
      :existingNames="sanitizedItems.map((el) => el.account)"
    />
    <AdminActivateAccount
      v-model="actions.showActivateAccountDialog"
      :current-account="currentAccount"
      @input="onCloseModal"
      @onSave="onActivateAccount"
    />
    <ExReOpenAccount
      v-model="actions.showReOpenAccountDialog"
      :current-account="currentAccount"
      @input="onCloseModal"
      @onAction="reOpenAccount"
    />
    <ExChangeAccountOwner
      v-model="actions.showChangeOwnerDialog"
      @checkEmail="checkEmailForGiveAccess"
      @onChangeContentType="giveAccessContentTypeProp = $event"
      :contentTypeProp="giveAccessContentTypeProp"
      :existingUser="existingUser"
      :current-account="currentAccount"
      @onSave="changeAccountOwner"
    />
    <AdminCloseAccountDialog
      v-model="actions.showCloseAccountDialog"
      :current-account="currentAccount"
      @input="onCloseModal"
      @onSave="onCloseAccount"
    />
    <AdminSuspendAccountDialog
      v-model="actions.showSuspendAccountDialog"
      :current-account="currentAccount"
      @input="onCloseModal"
      @onSave="onSuspendAccount"
    />
    <AdminDeleteAccountDialog
      v-model="actions.showDeleteAccountDialog"
      :current-account="currentAccount"
      @input="onCloseModal"
      @onSave="onDeleteAccount"
    />
  </section>
</template>

<script>
import AdminDataTable from "@components/DataTable/AdminDataTable.vue";
import AdminCreateAccountDialog from "@components/AdminCreateAccount/AdminCreateAccountDialog.vue";
import AdminActivateAccount from "@components/AdminActivateAccount/AdminActivateAccountDialog.vue";
import ExReOpenAccount from "@components/ReOpenAccount/ExReOpenAccount.vue";
import ExChangeAccountOwner from "@components/AdminChangeOwner/ExChangeAccountOwner.vue";
import { getSystemUsers, createUser, changeOwner, reOpenAccount } from "@/api";
import AdminCloseAccountDialog from "@components/AdminCloseAccount/AdminCloseAccountDialog.vue";
import AdminSuspendAccountDialog from "@components/AdminSuspendAccount/AdminSuspendAccountDialog.vue";
import AdminDeleteAccountDialog from "@components/AdminDeleteAccount/AdminDeleteAccountDialog.vue";
import {
  createAccount,
  getListOfAccounts,
  renameAccount,
  openAccount,
  closeAccount,
  suspendAccount,
  deleteAccount,
} from "@/api";
export default {
  name: "AccountList",
  components: {
    AdminCreateAccountDialog,
    AdminDataTable,
    AdminActivateAccount,
    ExReOpenAccount,
    ExChangeAccountOwner,
    AdminCloseAccountDialog,
    AdminSuspendAccountDialog,
    AdminDeleteAccountDialog,
  },
  data() {
    return {
      open: Boolean,
      paginationObj: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      accountItems: [],
      headers: [
        {
          text: "Account",
          sortable: false,
          value: "account",
          img: "icons/accounts.svg",
        },
        {
          text: "Account Owner",
          sortable: false,
          value: "accountowner",
          img: "icons/owner.svg",
        },
        {
          text: "Subscription",
          sortable: false,
          value: "subscription",
          img: "icons/subscriptions.svg",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      existingUser: null,
      actions: {
        showAddAccountDialog: false,
        showActivateAccountDialog: false,
        showReOpenAccountDialog: false,
        showChangeOwnerDialog: false,
        showCloseAccountDialog: false,
        showSuspendAccountDialog: false,
        showDeleteAccountDialog: false,
      },
      currentAccount: null,
      accountID: null,
      giveAccessContentTypeProp: "step1",
      dataAccounts: [],
      dataFilter: [],
    };
  },
  computed: {
    sanitizedItems() {
      return this.dataAccounts.map((el) => {
        const newEl = {};
        newEl.id = el.id;
        newEl.account = el?.name || "";
        newEl.userName =
          (el?.owner_first_name || "") + " " + (el?.owner_last_name || "");
        newEl.email = el?.owner_email || "";
        newEl.status = el?.status || "draft";
        return newEl;
      });
    },
  },
  async mounted() {
    try {
      this.$loader.show();
      await this.loadAccounts();
      this.dataAccounts = this.accountItems;
      this.$loader.hide();
    } catch (e) {
      console.log("e: ", e);
    }
  },
  methods: {
    appliedFilter(data) {
      this.dataFilter = data;
      if (data.length > 0) {
        this.dataAccounts = this.accountItems.filter((item) =>
          data.includes(item.status)
        );
        const totalCount = this.dataAccounts;
        this.paginationObj.total = totalCount.length;
        this.paginationObj.page = 1;
      } else {
        this.dataAccounts = this.accountItems;
        const totalCount = this.dataAccounts;
        this.paginationObj.total = totalCount.length;
      }
    },
    async reOpenAccount() {
      try {
        await reOpenAccount(this.currentAccount.id);
      } catch (e) {
        console.error(e);
      } finally {
        await this.loadAccounts();
        this.currentAccount = null;
        this.existingUser = null;
        this.actions.showReOpenAccountDialog = false;
      }
    },
    async checkEmailForGiveAccess(email) {
      try {
        this.$loader.show();
        const { data } = await getSystemUsers();
        const userFromEmail = data.results.find((el) => el.email == email);
        if (userFromEmail) {
          if (userFromEmail.state == "blocked") {
            this.giveAccessContentTypeProp = "emailBlocked";
          } else if (userFromEmail.state == "closed") {
            this.giveAccessContentTypeProp = "emailClosed";
          } else {
            this.existingUser = {
              firstName: userFromEmail.first_name,
              lastName: userFromEmail.last_name,
            };
            this.giveAccessContentTypeProp = "userExist";
          }
        } else {
          this.giveAccessContentTypeProp = "userDoesNotExist";
        }
      } catch (e) {
        console.error("error: ", e);
      } finally {
        this.$loader.hide();
      }
    },
    async changeAccountOwner(payload) {
      try {
        this.$loader.show();
        const data = {
          first_name: payload.firstName,
          last_name: payload.lastName,
          email: payload.email,
        };
        if (!this.existingUser) {
          await createUser(data);
          await changeOwner(this.currentAccount.id, { email: data.email });
        } else {
          await changeOwner(this.currentAccount.id, { email: data.email });
        }
        await this.loadAccounts();
      } catch (e) {
        console.error(e);
      } finally {
        this.currentAccount = null;
        this.existingUser = null;
        this.actions.showChangeOwnerDialog = false;
        this.$loader.hide();
      }
    },
    async onActivateAccount(data) {
      try {
        await openAccount(this.currentAccount.id, {
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
        });
        await this.loadAccounts();
        this.actions.showActivateAccountDialog = false;
      } catch (e) {
        console.log("e: ", e);
      }
    },
    onCloseModal(show) {
      if (!show) {
        this.currentAccount = null;
      }
    },
    async loadAccounts() {
      const { data } = await getListOfAccounts(1, 500);
      this.accountItems = data.results;
    },
    async saveAccount(accountName) {
      if (this.currentAccount && this.currentAccount.id) {
        try {
          const payload = {
            name: accountName,
          };
          await renameAccount(this.currentAccount.id, payload);
          await this.loadAccounts();
          this.actions.showAddAccountDialog = false;
        } catch (e) {
          console.log("error: ", e);
        }
      } else {
        try {
          const payload = {
            name: accountName,
          };
          await createAccount(payload);
          await this.loadAccounts();
          this.actions.showAddAccountDialog = false;
        } catch (e) {
          console.log("error: ", e);
        }
      }
    },
    showRowActions(data) {
      this.currentAccount = data.row;
      switch (data.action.value) {
        case "admin_rename_account":
          this.actions.showAddAccountDialog = true;
          break;
        case "admin_open_account":
          this.actions.showActivateAccountDialog = true;
          break;
        case "admin_change_account_owner":
          this.actions.showChangeOwnerDialog = true;
          break;
        case "admin_reopen_account":
          this.actions.showReOpenAccountDialog = true;
          break;
        case "admin_close_account":
          this.actions.showCloseAccountDialog = true;
          break;
        case "admin_suspend_account":
          this.actions.showSuspendAccountDialog = true;
          break;
        case "admin_delete_account":
          this.actions.showDeleteAccountDialog = true;
          break;
        default:
          break;
      }
    },
    onPageChange(event) {
      this.paginationObj.page = event;
    },
    async onCloseAccount() {
      try {
        await closeAccount(this.currentAccount.id);
        await this.loadAccounts();
        this.actions.showCloseAccountDialog = false;
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async onSuspendAccount() {
      try {
        const data = {
          reason: "Suspending account.",
        };
        await suspendAccount(this.currentAccount.id, data);
        await this.loadAccounts();
        this.actions.showSuspendAccountDialog = false;
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async onDeleteAccount() {
      try {
        await deleteAccount(this.currentAccount.id);
        await this.loadAccounts();
        this.actions.showDeleteAccountDialog = false;
      } catch (e) {
        console.error("e: ", e);
      }
    },
  },
};
</script>
<style scoped>
.count-icon-box {
  width: 25px;
  height: 20px;
  border-radius: 100px;
  border: 1px solid #eaecf0;
  text-align: center;
  font-weight: 500;
  color: #344054;
  font-size: 12px;
  background-color: #f9fafb;
}
.account-detail {
  border: 1px solid #eaecf0;
  padding: 1px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  height: 100%;
}
.account-detail .active-btn {
  background-color: #f9f5ff !important;
  color: #6941c6 !important;
  box-shadow: unset !important;
  border-radius: 16px !important;
  text-transform: lowercase;
  height: 30px !important;
  padding: 10px !important;
  font-weight: 500;
  font-size: 12px !important;
}
.account-title {
  display: flex;
  align-items: center;
  gap: 15px;
}
.account-title h2 {
  font-weight: 500;
  font-size: 18px;
  color: #101828;
}
.add-account-btn {
  background-color: #7f56d9 !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  color: #fff !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}
.account-table thead tr th {
  background-color: #f9fafb;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
}
.account-table thead tr th:nth-child(1) {
  width: 90px;
}
.account-table thead tr th:nth-child(2) {
  width: 90px;
}
.account-table thead tr th:nth-child(3) {
  width: 90px;
}
.account-table thead tr th:nth-child(4) {
  width: 90px;
}
.account-table tbody tr:hover {
  background-color: transparent !important;
}
.search-filled {
  padding-right: 0px;
}
.search-filled .v-input__slot fieldset {
  border-color: #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.search-filled input {
  color: #667085 !important;
}
.active-chip-box .v-chip--label {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-left: 0px !important;
}

.filter_wrp img {
  margin-right: 6px;
}

.chip_wrp {
  border-color: #d0d5dd;
  background-color: transparent !important;
  padding: 10px 14px;
  border-radius: 10px !important;
  margin-right: 10px;
  height: 36px !important;
  font-weight: 600;
  color: #344054;
  border: 1px solid #d0d5dd;
}

.filter_wrp {
  background-color: transparent !important;
  padding: 10px 14px !important;
  border-radius: 10px !important;
  margin-right: 10px;
  height: 36px !important;
  font-weight: 600;
  color: #344054;
  box-shadow: none !important;
  border: 1px solid #d0d5dd;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 24px !important;
}

.text_error .v-text-field__details {
  position: absolute !important;
  left: 0 !important;
  bottom: -21px !important;
}
</style>
