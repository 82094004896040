export default {
  install(Vue, options = {}) {
    Vue.prototype.$loader = {
      show() {
        options.store.commit("isLoading/setLoading", true);
      },
      hide() {
        options.store.commit("isLoading/setLoading", false);
      },
      setType(type) {
        options.store.commit("isLoading/setLoaderType", type);
      },
    };
  },
};
